import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavLogoImg,
  MobileIcon,
  NavMenuWrapperm,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  NavMenuWrapper,
} from './NavbarElements';

const logo = require('../../images/bb_cloud_v05.png').default;

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 100) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    // window.addEventListener('scroll', changeNav);
    window.addEventListener('scroll', setScrollNav(true));
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogoImg src={logo} onClick={toggleHome}></NavLogoImg>
            {/* <NavLogo to='/' onClick={toggleHome}>
              dolla
            </NavLogo> */}
            {/* <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon> */}
            <NavMenuWrapper>
              <NavMenu>
                <NavItem>
                  <NavLinks
                    to='develop'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-100} //match the navbar height
                    // activeClass='active'
                  >
                    Customize
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to='integrate'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-100} //match the navbar height
                  >
                    Integrate
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to='isv'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-100} //match the navbar height
                  >
                    ISVs
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to='products'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-100} //match the navbar height
                  >
                    Products
                  </NavLinks>
                </NavItem>
              </NavMenu>
            </NavMenuWrapper>
            {/* <NavBtn>
              <NavBtnLink to='/signin'>Sign In</NavBtnLink>
            </NavBtn> */}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
