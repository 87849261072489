export const homeObjOne = {
  id: 'develop',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Custom Development',
  headline: 'Solve Your Hard Problems',
  description:
    'Our services include custom development for Visualforce, Apex, Triggers, Lightning Web Components (LWC), Communities, Non-Profit Success Pack (NPSP) and more. Solutions for your clients, partners, and internal users.',
  buttonLabel: 'Get started',
  imgStart: false,
  img: require('../../images/undraw_proud_coder_v03.svg').default,
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: 'integrate',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Integration',
  headline: 'Centralize Your Data',
  description:
    'You have other systems besides Salesforce. We can bring those services and data into your Salesforce instance for a 360 view of your customer. Custom API solutions. Data migration with full fidelity.',
  buttonLabel: 'Learn more',
  imgStart: true,
  img: require('../../images/undraw_Data_re_v02.svg').default,
  alt: 'Piggy',
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: 'products',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Products',
  headline: 'Shopify Data Enrichment',
  description:
    'Shopify your Salesforce! Keep contact data current. View Shopify purchases by contact. Soon to be available in AppExchange. Contact us to join the beta program!',
  buttonLabel: 'Contact Us',
  imgStart: false,
  img: require('../../images/undraw_add_to_cart_v02.svg').default,
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true,
};
