import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ContactWrapper = styled.div`
  display: grid;
  z-index: 1;
  /* height: 660px; */
  width: 100%;
  /* max-width: 1100px; */
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 746px) {
    display: block;
  }
`;

export const ContactRow = styled.div`
  display: grid;
  margin-bottom: 15px;
  /* grid-auto-columns: minmax() (auto 1fr); */
  align-items: center;
  grid-template-areas: 'a a';
  gap: 10px;
  grid-auto-columns: 50%;

  @media screen and (max-width: 746px) {
    display: block;
    grid-template-areas: 'a';
    grid-auto-columns: 100%;
    align-items: top;
  }
`;

export const Column1 = styled.div`
  /* margin-bottom: 15px; */
  padding: 0 15px;
  height: 100%;

  background: linear-gradient(#007582, #00a9e9);
  /* grid-area: col1; */
  grid-column: 1;

  @media screen and (max-width: 746px) {
    height: 420px;
  }
`;

export const ColumnWrapper = styled.div`
  margin: 40px;
`;

export const ContactTitle = styled.h2`
  color: #fff;
`;

export const ContactImage = styled.img`
  margin: 40px;
  width: 90%;
  padding: 0 10px;

  @media screen and (max-width: 746px) {
    max-width: 52%;
  }
`;

export const ContactText = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
`;

export const FormContainer = styled.div`
  /* margin-bottom: 15px; */
  padding: 0 15px;
  background: #f4f6f9;
  z-index: 0;
  /* grid-area: col2; */
  grid-column: 2;
`;

export const FormWrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* @media screen and (max-width: 480px) {
    height: 80%;
  } */
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* @media screen and (max-width: 480px) {
    padding: 10px;
  } */
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

export const FormRowField = styled.div`
  width: 48%;
  margin: 0 2% 0 0;
`;

export const Form = styled.form`
  font-size: 2rem;
  background: #fff;
  max-width: 500px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 24px 32px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

  @media screen and (max-width: 480px) {
    padding: 32px 32px;
  }
`;

export const FormH1 = styled.h1`
  margin-bottom: 40px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 0.875rem;
  color: #54698d;
`;

export const FormInput = styled.input`
  font-size: 0.875rem;
  padding: 16px;
  margin-bottom: 32px;
  border: solid 1px #d8dde6;
  border-radius: 4px;
  width: 100%;
  height: 40px;
`;

export const FormTextArea = styled.textarea`
  font-size: 0.875rem;
  padding: 16px;
  margin-bottom: 32px;
  border: solid 1px #d8dde6;
  border-radius: 4px;
  width: 100%;
  /* overflow: hidden; */
  resize: none;
  /* height: 40px; */
`;

export const FormSelect = styled.select`
  font-size: 0.875rem;
  padding: 16px 16px;
  margin-bottom: 32px;
  border: none;
  border-radius: 4px;
  width: 100%;
`;

export const FormButton = styled.input`
  /* background: #004864; */
  padding: 16px 0;
  border: solid 1px #d8dde6;
  border-radius: 4px;
  color: #010101;
  font-size: 1rem;
  cursor: pointer;
`;

export const Text = styled.span`
  text-align: center;
  margin-top: 24px;
  color: #fff;
  font-size: 14px;
`;

export const Icon = styled(Link)`
  /* margin-left: 32px;
  margin-top: 32px; */
  margin: 64px 0;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 32px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;

export const LogoWrap = styled.div`
  width: 300px;
  margin: 20px auto 10px;
`;

export const Logo = styled.img`
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border: none;
`;
