import React, { useState } from 'react';
// import Video from '../../videos/istockphoto-599283802-640_adpp_is.mp4';
import { Button } from '../ButtonElements';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  ImgBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  HeroBtnLink,
  ArrowForward,
  ArrowRight,
} from './HeroElements';

const img = require('../../images/bb-bg_v03.svg').default;

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id='home'>
      <HeroBg>
        {/* <VideoBg autoPlay loop muted src={Video} type='video/mp4' /> */}
        <ImgBg src={img} />
      </HeroBg>
      <HeroContent>
        <HeroH1>Salesforce Innovation</HeroH1>
        <HeroP>
          Blue Bolt Advanced Integrations has been implementing Salesforce for
          over 12 years. Our CRM expertise and developer services can help you
          transform your business or non-profit. We solve hard problems at a
          reasonable cost.
        </HeroP>
        <HeroBtnWrapper>
          <Button
            to='/contact'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary='true'
            dark='true'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80} //match the navbar height
          >
            Get started {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
