import React from 'react';
import Icon1 from '../../images/undraw_pair_programming_v03.svg';
import Icon2 from '../../images/undraw_dev_focus_v03.svg';
import Icon3 from '../../images/undraw_Security_on_v02.svg';
import {
  ServicesContainer,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH1,
  ServicesH2,
  ServicesP,
  ServicesSubtext,
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='isv'>
      <ServicesH1>AppExchange ISVs</ServicesH1>
      <ServicesSubtext>
        Got a new idea for AppExchange? We can help from inception to release!
      </ServicesSubtext>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Code Review</ServicesH2>
          <ServicesP>
            Eliminate errors before release. Ensure maintainability.
          </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Packaging</ServicesH2>
          <ServicesP>
            Modularize your code to simplify support and enhancement.
          </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Security Review</ServicesH2>
          <ServicesP>
            Ensure the Salesforce security review goes smoothly.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
